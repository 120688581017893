function DbgCameraInfo({ cameraWidth, cameraHeight, captureSize, activeMask }) {
  return (
    <div className="mx-6 font-sans text-sm flex justify-between">
      <div>
        Camera:{" "}
        {!!cameraWidth && !!cameraHeight && (
          <>
            {cameraWidth}x{cameraHeight}
          </>
        )}
      </div>
      <div>Mask: {activeMask}</div>
      <div>
        Capture:{" "}
        {!!captureSize && (
          <>
            {captureSize}x{captureSize}
          </>
        )}
      </div>
    </div>
  );
}

export default DbgCameraInfo;
