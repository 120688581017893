import React from "react";

function Button({ children, bgcolor = "bg-white", icon = false, onClick }) {
  const tw = ["border-2 border-green py-3 px-5 font-sans font-bold text-primary text-xl leading-none flex"];
  tw.push(bgcolor);

  return (
    <button className={tw.join(" ")} onClick={onClick}>
      {icon === "camera" && (
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="camera" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-5 h-5 mr-2">
          <path
            fill="currentColor"
            d="M342.7 144H464v288H48V144h121.3l24-64h125.5l23.9 64zM324.3 32h-131c-20 0-37.9 12.4-44.9 31.1L136 96H48c-26.5 0-48 21.5-48 48v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V144c0-26.5-21.5-48-48-48h-88l-14.3-38c-5.8-15.7-20.7-26-37.4-26zM256 408c-66.2 0-120-53.8-120-120s53.8-120 120-120 120 53.8 120 120-53.8 120-120 120zm0-192c-39.7 0-72 32.3-72 72s32.3 72 72 72 72-32.3 72-72-32.3-72-72-72z"
            className=""
          ></path>
        </svg>
      )}
      <span>{children}</span>
    </button>
  );
}

export default Button;
