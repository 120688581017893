import React, { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

import Logo from "../images/catolica-blue.png";
import Picture from "../images/picture.jpg";

import Mask1 from "../images/mask-1.png";
import Mask2 from "../images/mask-2.png";
import Mask3 from "../images/mask-3.png";

import DbgCameraInfo from "./DbgCameraInfo";
import Heading from "./Heading";
import Button from "./Button";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PhotoBooth() {
  const qs = useQuery();
  const dbg = qs.get("dbg");

  const [image, setImage] = useState(false);
  const [step, setStep] = useState(1);
  const [cameraStream, setCameraStream] = useState(null);

  const [loading, setLoading] = useState(false);

  const [cameraWidth, setCameraWidth] = useState(0);
  const [cameraHeight, setCameraHeight] = useState(0);
  const [aspectRatio, setAspectRatio] = useState(Math.round((1280 / 720) * 10000) / 10000);
  const [streamStyle, setStremStyle] = useState({});
  const [captureSize, setCaptureSize] = useState(0);
  const [activeMask, setActiveMask] = useState(0);

  const [shootReady, setShootReady] = useState(false);
  const [snapshotReady, setSnapshotReady] = useState(false);

  const [disableNextButton, setDisableNextButton] = useState(true);

  //const stream = document.getElementById("stream");
  const stream = useRef(null);
  const capture = useRef(null);
  const snapshot = useRef(null);
  const upload = useRef(null);
  const placeholder = useRef(null);

  const handleNext = () => {
    setStep(step + 1);
    setDisableNextButton(true);
  };

  const stopStreaming = () => {
    if (cameraStream === null) {
      return;
    }

    const track = cameraStream.getTracks()[0];
    track.stop();
    stream.current.load();

    setCameraStream(null);
  };

  const handleTakePhoto = () => {
    const mediaSupport = "mediaDevices" in navigator;

    if (!mediaSupport) {
      alert("Your browser does not support media devices.");
      return;
    }

    if (cameraStream !== null) {
      return;
    }

    setLoading(true);
    setSnapshotReady(false);

    const constraints = {
      video: {
        width: {
          ideal: 1920,
        },
        height: {
          ideal: 1080,
        },
        facingMode: "user",
      },
      audio: false,
    };

    /*
    const constraints = {
      video: {
        width: { ideal: 1920 },
        height: { ideal: 1080 },
      },
    }:
    */

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((mediaStream) => {
        const streamSettings = mediaStream.getVideoTracks()[0].getSettings();
        const aspectRatio = Math.round((streamSettings.width / streamSettings.height) * 10000) / 10000;
        const captureSize = Math.min(streamSettings.width, streamSettings.height);

        setCameraWidth(streamSettings.width);
        setCameraHeight(streamSettings.height);
        setAspectRatio(aspectRatio);
        setCaptureSize(captureSize);

        if (aspectRatio > 1) {
          const diff = streamSettings.width - streamSettings.height;
          const offset = (diff / streamSettings.width / 2) * 100;

          setStremStyle({
            width: `${aspectRatio * 100}%`,
            // transform: `translateX(-${(aspectRatio * 100 - 100) / 2}%)`,
            transform: `translateX(-${offset}%)`,
          });
        } else {
          const diff = streamSettings.height - streamSettings.width;
          const offset = (diff / streamSettings.height / 2) * 100;

          setStremStyle({
            height: `${(1 / aspectRatio) * 100}%`,
            // marginTop: `-${((1 / aspectRatio) * 100 - 100) / 2}%`,
            transform: `translateY(-${offset}%)`,
          });
        }

        setCameraStream(mediaStream);

        stream.current.srcObject = mediaStream;
        stream.current.play();

        // $(".snapshot-wrapper").hide();
        // $("#btnTakePhoto").hide();
        // $("#btnShoot").show();

        setShootReady(true);

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(`Unable to access camera: ${err}`);
        setLoading(false);
      });
  };

  const handleShoot = () => {
    console.log("handleShoot");

    if (cameraStream === null) {
      return;
    }

    capture.current.width = captureSize;
    capture.current.height = captureSize;

    const ctx = capture.current.getContext("2d");
    const img = new Image();

    if (aspectRatio > 1) {
      const offset = Math.abs(Math.round((cameraWidth - cameraHeight) / 2));
      ctx.drawImage(stream.current, offset, 0, captureSize, captureSize, 0, 0, captureSize, captureSize);
    } else {
      const offset = Math.abs(Math.round((cameraHeight - cameraWidth) / 2));
      ctx.drawImage(stream.current, 0, offset, captureSize, captureSize, 0, 0, captureSize, captureSize);
    }

    img.src = capture.current.toDataURL("image/png");
    setLoading(true);
    img.style.opacity = 0;
    img.onload = () => {
      ctx.translate(captureSize, 0);
      ctx.scale(-1, 1);
      ctx.drawImage(img, 0, 0);
      ctx.save();

      img.src = capture.current.toDataURL("image/png");
      img.onload = () => {
        img.style.opacity = 1;
        setLoading(false);
      };
    };

    snapshot.current.innerHTML = "";
    snapshot.current.appendChild(img);

    stopStreaming();
    setShootReady(false);
    setSnapshotReady(true);
    setDisableNextButton(false);
  };

  const handleUpload = () => {
    upload.current.click();
  };

  const handleFileChange = () => {
    const [file] = upload.current.files;

    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("file", file, file.name);

    setLoading(true);
    axios.post(`${process.env.REACT_APP_API}/upload`, formData).then((response) => {
      if (response.status === 200 && response.data.status === "ok") {
        setLoading(false);
      }
    });

    // console.log(file);

    const img = new Image();
    img.setAttribute("src", URL.createObjectURL(file));
    snapshot.current.innerHTML = "";
    snapshot.current.appendChild(img);

    // capture.current.width = 1000;
    // capture.current.height = 1000;

    // img.onload = function (e) {
    //   console.log("load");
    //   const ctx = capture.current.getContext("2d");
    //   ctx.drawImage(img, 0, 0, captureSize, captureSize, 0, 0, captureSize, captureSize);
    // };

    // img.src = capture.current.toDataURL("image/png");
    // snapshot.current.innerHTML = "";
    // snapshot.current.appendChild(img);

    /*

    if (aspectRatio > 1) {
      const offset = Math.abs(Math.round((cameraWidth - cameraHeight) / 2));
      ctx.drawImage(stream.current, offset, 0, captureSize, captureSize, 0, 0, captureSize, captureSize);
    } else {
      const offset = Math.abs(Math.round((cameraHeight - cameraWidth) / 2));
      ctx.drawImage(stream.current, 0, offset, captureSize, captureSize, 0, 0, captureSize, captureSize);
    }

    */

    // placeholder.current.setAttribute("src", URL.createObjectURL(file));

    // if (aspectRatio > 1) {
    //   const offset = Math.abs(Math.round((cameraWidth - cameraHeight) / 2));
    //   ctx.drawImage(stream.current, offset, 0, captureSize, captureSize, 0, 0, captureSize, captureSize);
    // } else {
    //   const offset = Math.abs(Math.round((cameraHeight - cameraWidth) / 2));
    //   ctx.drawImage(stream.current, 0, offset, captureSize, captureSize, 0, 0, captureSize, captureSize);
    // }

    // console.log(file);

    setSnapshotReady(true);
    setDisableNextButton(false);
  };

  const handleApply = () => {
    console.log("handleApply");

    const img = document.querySelector("#snapshot img");
    if (img) {
      const src = img.getAttribute("src");

      axios
        .post(`${process.env.REACT_APP_API}/pictures`, {
          image: src,
        })
        .then((response) => {
          if (response.status === 200 && response.data.status === "ok") {
            setImage(response.data.image);
            setDisableNextButton(false);
          }
        });

      console.log(src);
    }
  };

  const handleUndo = () => {
    setStep(step - 1);
  };

  const handlePrevMask = () => {
    const m = activeMask - 1;
    setActiveMask(m < 0 ? 2 : m);
  };

  const handleNextMask = () => {
    const m = activeMask + 1;
    setActiveMask(m > 2 ? 0 : m);
  };

  const handleShare = () => {
    console.log("handleShare");
  };

  return (
    <div>
      <div className="max-w-3xl mx-auto min-h-screen bg-white">
        <div className="logo-blue mx-6 pt-7 mb-10">
          <img className="w-36" src={Logo} alt="Católica Lisbon School of Business & Economics" />
        </div>

        <Heading step={step}></Heading>

        {dbg && (
          <div className="mx-6 text-sm">
            <div>aspectRatio:{aspectRatio}</div>
            <div>{JSON.stringify(streamStyle)}</div>
          </div>
        )}

        {dbg && <DbgCameraInfo cameraWidth={cameraWidth} cameraHeight={cameraHeight} captureSize={captureSize} activeMask={activeMask}></DbgCameraInfo>}

        <div className="mx-6 mb-4 relative">
          {/* Imagem Default */}
          <img ref={placeholder} className="w-full" data-src="./images/picture.jpg" src={Picture} alt="Future Me" id="imgPlaceholder" />

          {loading && (
            <div className="absolute top-0 left-0 w-full h-full overflow-hidden bg-white bg-opacity-40 flex items-center justify-center loading">
              <div className="lds-dual-ring"></div>
            </div>
          )}

          {/* Video Stream */}
          {!snapshotReady && (
            <div className="absolute top-0 left-0 w-full h-full overflow-hidden stream-wrapper">
              <div className="stream-container" style={streamStyle}>
                <video autoPlay={true} playsInline={true} ref={stream} id="stream" width="100%" height="100%"></video>
              </div>
            </div>
          )}

          {/* snapshot && Masks */}
          <div className="absolute top-0 left-0 w-full h-full overflow-hidden snapshot-wrapper" style={{ opacity: snapshotReady ? 1 : 0 }}>
            <div className="relative w-full h-full">
              <div ref={snapshot} id="snapshot" className="w-full h-full"></div>

              {[2, 3].includes(step) && (
                <div className="absolute w-full top-0 left-0 masks">
                  <img className={activeMask % 3 === 0 ? "w-full" : "w-full hidden"} src={Mask1} alt="Fresh student" />
                  <img className={activeMask % 3 === 1 ? "w-full" : "w-full hidden"} src={Mask2} alt="I’m in" />
                  <img className={activeMask % 3 === 2 ? "w-full" : "w-full hidden"} src={Mask3} alt="Let’s do this" />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Take photo / Upload */}
        {step === 1 && (
          <div className="mx-6 flex justify-between mb-8 actions-1">
            {shootReady && (
              <Button bgcolor="bg-green" icon="camera" onClick={handleShoot}>
                Shoot
              </Button>
            )}

            {!shootReady && (
              <Button bgcolor="bg-green" onClick={handleTakePhoto}>
                Take photo
              </Button>
            )}

            <input ref={upload} type="file" id="file" accept="image/*" onChange={handleFileChange} style={{ display: "none" }} />

            <Button onClick={handleUpload}>Upload</Button>
          </div>
        )}

        {/* Apply / Undo */}
        {step === 2 && (
          <div className="mx-6 flex justify-between mb-8 actions-2">
            <button className="w-12 h-12 flex items-center justify-center bg-primary" id="btnPrevMask" onClick={handlePrevMask}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.57 36.54" className="text-white w-4 h-4 transform rotate-180">
                <path
                  d="M2 0C1.43 0 .86.25.46.72a2 2 0 00.26 2.82l14.72 14.73L.72 33a2 2 0 00-.26 2.82 2 2 0 002.82.26l16.57-16.27c.46-.38.72-.94.72-1.54a2 2 0 00-.72-1.54L3.28.46C2.9.15 2.45 0 2 0z"
                  fill="currentColor"
                />
              </svg>
            </button>

            <Button bgcolor="bg-green" onClick={handleApply}>
              Apply
            </Button>

            <Button onClick={handleUndo}>Undo</Button>

            <button className="w-12 h-12 flex items-center justify-center bg-primary" id="btnNextMask" onClick={handleNextMask}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.57 36.54" className="text-white w-4 h-4">
                <path
                  d="M2 0C1.43 0 .86.25.46.72a2 2 0 00.26 2.82l14.72 14.73L.72 33a2 2 0 00-.26 2.82 2 2 0 002.82.26l16.57-16.27c.46-.38.72-.94.72-1.54a2 2 0 00-.72-1.54L3.28.46C2.9.15 2.45 0 2 0z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </div>
        )}

        {step === 3 && (
          <div className="mx-6 flex justify-between mb-8 actions-2">
            <Button bgcolor="bg-green" onClick={handleShare}>
              Share your post
            </Button>

            {image && (
              <div className="text-center">
                <a
                  className="border-2 border-green py-3 px-5 font-sans font-bold text-primary text-xl leading-none flex"
                  rel="noreferrer"
                  target="_blank"
                  href={`${process.env.REACT_APP_API}/mask/${image.replace(/\.\w+/, "")}/${activeMask}`}
                >
                  Export
                </a>
              </div>
            )}
          </div>
        )}

        {[1, 2].includes(step) && (
          <div className="mx-6 flex justify-end">
            <button className="flex items-center" disabled={disableNextButton} onClick={handleNext}>
              <span className="text-primary font-sans font-bold text-3xl mr-5">Next</span>
              <span className="w-12 h-12 flex items-center justify-center bg-primary">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.57 36.54" className="text-white w-4 h-4">
                  <path
                    d="M2 0C1.43 0 .86.25.46.72a2 2 0 00.26 2.82l14.72 14.73L.72 33a2 2 0 00-.26 2.82 2 2 0 002.82.26l16.57-16.27c.46-.38.72-.94.72-1.54a2 2 0 00-.72-1.54L3.28.46C2.9.15 2.45 0 2 0z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </button>
          </div>
        )}
      </div>

      <div className="bg-green hidden">
        <canvas ref={capture} id="capture" className="w-full"></canvas>
      </div>
    </div>
  );
}

export default PhotoBooth;
