/*global FB*/

import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Webcam from "webcam-easy";
import axios from "axios";

import Logo from "../images/catolica-blue.png";
import Heading from "./Heading";
import Button from "./Button";

import Mask1 from "../images/mask-1.png";
import Mask2 from "../images/mask-2.png";
import Mask3 from "../images/mask-3.png";

function WebcamEasy() {
  const stream = useRef(null);
  const capture = useRef(null);
  const upload = useRef(null);
  const snapshot = useRef(null);

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [image, setImage] = useState(false);

  const [cameraWidth, setCameraWidth] = useState(0);
  const [cameraHeight, setCameraHeight] = useState(0);
  const [aspectRatio, setAspectRatio] = useState(Math.round((1280 / 720) * 10000) / 10000);
  const [captureSize, setCaptureSize] = useState(0);
  const [activeMask, setActiveMask] = useState(0);
  const [streamStyle, setStremStyle] = useState({});
  const [squareActive, setSquareActive] = useState("");

  const [webcam, setWebcam] = useState(false);

  const [shootReady, setShootReady] = useState(false);
  const [snapshotReady, setSnapshotReady] = useState(false);
  const [disableNextButton, setDisableNextButton] = useState(true);

  const handleNext = () => {
    setStep(step + 1);
    setDisableNextButton(true);
  };

  const handleUndo = () => {
    setStep(step - 1);
  };

  const handlePrevMask = () => {
    const m = activeMask - 1;
    setActiveMask(m < 0 ? 2 : m);
  };

  const handleNextMask = () => {
    const m = activeMask + 1;
    setActiveMask(m > 2 ? 0 : m);
  };

  const handleApply = () => {
    const img = document.querySelector("#snapshot img");

    if (img) {
      setLoading(true);
      const src = img.getAttribute("src");

      if (src.match(/\.jpg/)) {
        const parts = src.split("/").reverse();
        setImage(parts[0]);
        setDisableNextButton(false);
        setLoading(false);
        return;
      }

      axios
        .post(`${process.env.REACT_APP_API}/pictures`, {
          image: src,
        })
        .then((response) => {
          if (response.status === 200 && response.data.status === "ok") {
            setImage(`${response.data.image}`);
            setDisableNextButton(false);
          }

          setLoading(false);
        });
    }
  };

  const handleShare = (img) => {
    const shareData = {
      title: "Future You",
      text: "Business and Administration Undergraduate Program.",
      url: img,
    };

    if (!navigator.share) {
      FB.ui(
        {
          display: "popup",
          method: "share",
          href: shareData.url,
        },
        function (response) {}
      );

      return;
    }

    navigator
      .share(shareData)
      .then(() => {
        console.log("share ok");
      })
      .catch((e) => {
        console.log("share err");
      });
  };

  const handleTakePhoto = () => {
    const webcam = new Webcam(stream.current, "user", capture.current);
    setWebcam(webcam);

    setShootReady(false);
    setLoading(true);

    webcam
      .start()
      .then(() => {
        const streamSettings = webcam._streamList[0].getVideoTracks()[0].getSettings();
        const aspectRatio = Math.round((streamSettings.width / streamSettings.height) * 10000) / 10000;
        const captureSize = Math.min(streamSettings.width, streamSettings.height);

        setCameraWidth(streamSettings.width);
        setCameraHeight(streamSettings.height);
        setAspectRatio(aspectRatio);
        setCaptureSize(captureSize);

        if (aspectRatio > 1) {
          const diff = streamSettings.width - streamSettings.height;
          const offset = (diff / streamSettings.width / 2) * 100;

          setStremStyle({
            width: `${aspectRatio * 100}%`,
            transform: `translateX(-${offset}%)`,
          });
        } else {
          const diff = streamSettings.height - streamSettings.width;
          const offset = (diff / streamSettings.height / 2) * 100;

          setStremStyle({
            height: `${(1 / aspectRatio) * 100}%`,
            transform: `translateY(-${offset}%)`,
          });
        }

        setSquareActive("video");
        setShootReady(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleShoot = () => {
    capture.current.width = captureSize;
    capture.current.height = captureSize;

    // const picture = webcam.snap();

    const ctx = capture.current.getContext("2d");
    const img = new Image();

    if (aspectRatio > 1) {
      const offset = Math.abs(Math.round((cameraWidth - cameraHeight) / 2));
      ctx.drawImage(stream.current, offset, 0, captureSize, captureSize, 0, 0, captureSize, captureSize);
    } else {
      const offset = Math.abs(Math.round((cameraHeight - cameraWidth) / 2));
      ctx.drawImage(stream.current, 0, offset, captureSize, captureSize, 0, 0, captureSize, captureSize);
    }

    img.src = capture.current.toDataURL("image/png");
    // setLoading(true);
    // img.style.opacity = 0;
    img.onload = () => {
      ctx.translate(captureSize, 0);
      ctx.scale(-1, 1);
      ctx.drawImage(img, 0, 0);
      ctx.save();

      img.src = capture.current.toDataURL("image/png");
      img.onload = () => {
        // img.style.opacity = 1;
        // setLoading(false);
        setSquareActive("snapshot");
      };
    };

    webcam.stop();
    setShootReady(false);
    setDisableNextButton(false);

    snapshot.current.innerHTML = "";
    snapshot.current.appendChild(img);
  };

  const handleUpload = () => {
    upload.current.click();
  };

  const handleFileChange = () => {
    const [file] = upload.current.files;

    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("file", file, file.name);

    setLoading(true);
    axios.post(`${process.env.REACT_APP_API}/upload`, formData).then((response) => {
      if (response.status === 200 && response.data.status === "ok") {
        console.log(response.data);

        const img = new Image();
        img.setAttribute("src", `${process.env.REACT_APP_API}/images/${response.data.image}`);
        snapshot.current.innerHTML = "";
        snapshot.current.appendChild(img);

        setLoading(false);
      }
    });

    // const img = new Image();
    // img.setAttribute("src", URL.createObjectURL(file));
    // snapshot.current.innerHTML = "";
    // snapshot.current.appendChild(img);

    setSquareActive("snapshot");
    setSnapshotReady(true);
    setDisableNextButton(false);
  };

  return (
    <div className="mx-6">
      <div className="pt-7 mb-10">
        <Link to="/">
          <img className="w-36" src={Logo} alt="Católica Lisbon School of Business & Economics" />
        </Link>
      </div>

      <Heading step={step}></Heading>

      <div className="square mb-4">
        <div className="square-content">
          {loading && (
            <div className="absolute top-0 left-0 w-full h-full z-10 overflow-hidden bg-white bg-opacity-40 flex items-center justify-center loading">
              <div className="lds-dual-ring"></div>
            </div>
          )}

          {/* Video */}
          <div className={squareActive === "video" ? "stream-container" : "stream-container opacity-0"} style={streamStyle}>
            <video autoPlay={true} playsInline={true} ref={stream} width="100%" height="100%"></video>
          </div>

          {/* Snapshot */}
          <div ref={snapshot} id="snapshot" className={squareActive === "snapshot" ? "w-full h-full absolute top-0 left-0" : "w-full h-full absolute top-0 left-0 opacity-0"}></div>

          {[2, 3].includes(step) && (
            <div className="absolute w-full top-0 left-0 masks">
              <img className={activeMask % 3 === 0 ? "w-full" : "w-full hidden"} src={Mask1} alt="Fresh student" />
              <img className={activeMask % 3 === 1 ? "w-full" : "w-full hidden"} src={Mask2} alt="I’m in" />
              <img className={activeMask % 3 === 2 ? "w-full" : "w-full hidden"} src={Mask3} alt="Let’s do this" />
            </div>
          )}
        </div>
      </div>

      {/* Take photo / Upload */}
      {step === 1 && (
        <div className="flex justify-between mb-8">
          {shootReady && (
            <Button bgcolor="bg-green" icon="camera" onClick={handleShoot}>
              Shoot
            </Button>
          )}

          {!shootReady && (
            <Button bgcolor="bg-green" onClick={handleTakePhoto}>
              Take photo
            </Button>
          )}

          <input ref={upload} type="file" id="file" accept="image/*" onChange={handleFileChange} style={{ display: "none" }} />

          <Button onClick={handleUpload}>Upload</Button>
        </div>
      )}

      {/* Apply / Undo */}
      {step === 2 && (
        <div className="flex justify-between mb-8">
          <button className="w-12 h-12 flex items-center justify-center bg-primary" id="btnPrevMask" onClick={handlePrevMask}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.57 36.54" className="text-white w-4 h-4 transform rotate-180">
              <path
                d="M2 0C1.43 0 .86.25.46.72a2 2 0 00.26 2.82l14.72 14.73L.72 33a2 2 0 00-.26 2.82 2 2 0 002.82.26l16.57-16.27c.46-.38.72-.94.72-1.54a2 2 0 00-.72-1.54L3.28.46C2.9.15 2.45 0 2 0z"
                fill="currentColor"
              />
            </svg>
          </button>

          <Button bgcolor="bg-green" onClick={handleApply}>
            Apply
          </Button>

          <Button onClick={handleUndo}>Undo</Button>

          <button className="w-12 h-12 flex items-center justify-center bg-primary" id="btnNextMask" onClick={handleNextMask}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.57 36.54" className="text-white w-4 h-4">
              <path
                d="M2 0C1.43 0 .86.25.46.72a2 2 0 00.26 2.82l14.72 14.73L.72 33a2 2 0 00-.26 2.82 2 2 0 002.82.26l16.57-16.27c.46-.38.72-.94.72-1.54a2 2 0 00-.72-1.54L3.28.46C2.9.15 2.45 0 2 0z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
      )}

      {/* Share your post / Export */}
      {step === 3 && (
        <div className="flex justify-between mb-8">
          <Button
            bgcolor="bg-green"
            onClick={() => {
              handleShare(`${process.env.REACT_APP_API}/mask/${image}/${activeMask}`);
            }}
          >
            Share your post
          </Button>

          {image && (
            <div className="text-center">
              <a
                className="border-2 border-green py-3 px-5 font-sans font-bold text-primary text-xl leading-none flex"
                rel="noreferrer"
                target="_blank"
                href={`${process.env.REACT_APP_API}/mask/${image}/${activeMask}/export`}
              >
                Export
              </a>
            </div>
          )}
        </div>
      )}

      {/* Next */}
      {[1, 2].includes(step) && (
        <div className="flex justify-end">
          <button className="flex items-center" disabled={disableNextButton} onClick={handleNext}>
            <span className="text-primary font-sans font-bold text-3xl mr-5">Next</span>
            <span className="w-12 h-12 flex items-center justify-center bg-primary">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.57 36.54" className="text-white w-4 h-4">
                <path
                  d="M2 0C1.43 0 .86.25.46.72a2 2 0 00.26 2.82l14.72 14.73L.72 33a2 2 0 00-.26 2.82 2 2 0 002.82.26l16.57-16.27c.46-.38.72-.94.72-1.54a2 2 0 00-.72-1.54L3.28.46C2.9.15 2.45 0 2 0z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </button>
        </div>
      )}

      <canvas id="canvas" ref={capture}></canvas>
    </div>
  );
}

export default WebcamEasy;
