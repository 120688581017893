import React from "react";

function Heading({ step }) {
  return (
    <div className="mb-6">
      {step === 1 && (
        <div className="step active">
          <h4 className="text-primary font-sans font-bold text-4xl leading-none mb-3">Step 1</h4>
          <p className=" font-sans text-lg leading-snug">Take a photo or upload it from your library.</p>
        </div>
      )}
      {step === 2 && (
        <div className="step">
          <h4 className="text-primary font-sans font-bold text-4xl leading-none mb-3">Step 2</h4>
          <p className="font-sans text-lg leading-snug">
            Choose your custom <br />
            CATÓLICA-LISBON template:
          </p>
        </div>
      )}
      {step === 3 && (
        <div className="step">
          <h4 className="text-primary font-sans font-bold text-4xl leading-none mb-3">Step 3</h4>
          <p className=" font-sans text-lg leading-snug">Share it with your family and friends on your social media network.</p>
        </div>
      )}
    </div>
  );
}

export default Heading;
