import React from "react";
import { Link } from "react-router-dom";
import Logo from "../images/catolica-white.png";

function Home() {
  return (
    <div>
      <div className="min-h-screen bg-primary">
        <div className="logo mx-6 pt-8 mb-9">
          <img className="w-60" src={Logo} alt="Católica Lisbon School of Business & Economics" />
        </div>

        <div className="font-sans font-bold text-7xl text-white hey-future-you px-6 pb-8">
          Hey
          <br />
          Future
          <br />
          You
        </div>

        <div className="font-sans font-bold text-4xl text-green mx-6">
          Maria Morais
          <br />
          Soares
        </div>

        <div className="font-sans text-white mx-6 mb-4">
          Welcome to Católica Lisbon’s
          <br />
          Undergraduate Programs
          <br />
          We’re so proud to have you on board with us.
        </div>

        <div className="font-sans text-lg font-bold text-white mx-6 mb-4">Share your achievement with the world!</div>

        <div className="mx-6">
          <Link className="flex items-center" to="/start">
            <span className="text-white font-sans font-bold text-3xl mr-5">Start now</span>
            <span className="w-12 h-12 flex items-center justify-center bg-white">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.57 36.54" className="text-primary w-4 h-4">
                <path
                  d="M2 0C1.43 0 .86.25.46.72a2 2 0 00.26 2.82l14.72 14.73L.72 33a2 2 0 00-.26 2.82 2 2 0 002.82.26l16.57-16.27c.46-.38.72-.94.72-1.54a2 2 0 00-.72-1.54L3.28.46C2.9.15 2.45 0 2 0z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Home;
