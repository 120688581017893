import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Example from "./components/Example";
import Home from "./components/Home";
import PhotoBooth from "./components/‎PhotoBooth";
import WebcamEasy from "./components/WebcamEasy";

function App() {
  return (
    <Router>
      <div className="App max-w-3xl mx-auto">
        <Switch>
          <Route path="/" exact>
            <Home></Home>
          </Route>

          <Route path="/start-old" exact>
            <PhotoBooth></PhotoBooth>
          </Route>

          <Route path="/start">
            <WebcamEasy></WebcamEasy>
          </Route>

          <Route path="/example">
            <Example />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
